const JobAnalysis = () => {
    return ( <div>
           <p className=" font-medium ">Top Target will collaborate with you to analyze your organization’s jobs and design them to achieve the organization’s objective and at the same time satisfies the needs of the employees who will do them. This service will involve the following key tasks:</p>
       <ul className="list-disc pl-5 mt-5 space-y-2">
        { [
      "Determining the Job Requirements for every post in the organization, that will reflect the different duties, tasks, and responsibilities contained in jobs.",
      "Producing the Job Description in a format that will contain a job title, a job identification section, and main duties and responsibilities section.",
      "Producing the Job Specification in format that will contain, education, experience, specialized training technical skills, interpersonal skills and behavioral attributes necessary for job success."
    ].map((item)=>{
            return <li>{item}</li>
        })
}
       </ul>
    </div> );
}
 
export default JobAnalysis;