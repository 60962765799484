import { createContext, useEffect, useState } from "react";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import { addVisitor } from "../controllers/visitorsController";
import HomePage from "../pages/homePage";
import {Outlet,useLocation} from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../utils/firebase";
import { getUser } from "../controllers/authController";
import PageLoader from "../components/pageLoader";
import {Toaster} from "react-hot-toast";
import {Helmet} from "react-helmet"
export const UserContext = createContext()

const WebsiteLayout = () => {
    const {pathname} = useLocation()
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      onAuthStateChanged(auth,(user)=>{
        if(user){
           getUser(user.email).then((data)=>{
            setUser(data)
            setLoading(false)
           })
        }else{
           setUser(null)
           setLoading(false)

        }
   })
   
    }, []);
useEffect(() => {
    if(localStorage.getItem("visitedToptarget")){
    }else{
        localStorage.setItem("visitedToptarget","true")
        addVisitor({page:pathname})
    }
}, []);
    return ( loading?<PageLoader height={'h-screen'}/>: <div>
          <Helmet>
        <title>Top Target</title>
        <meta name="description" content="Top Target is an emerging leader organization in providing End to End Management consulting services that form the basic building blocks required for implementing the Enterprise Project Management and Human Resources Management. At Top Target, we work closely with our valued customers to increase their readiness level to implement Enterprise Project Management and Human Resources Management systems. This includes Recruitment, training and development, compensation and benefit management and retention programs together with other services. Top Target differentiation is in its focused business model and its specialized Management Consultancy service portfolio that makes Top Target a unique, credible, unbiased service provider. Our achievement is a result of our continuous endeavors to combine talent, quality, and values together." />
        <meta property="og:title" content="Top Target" />
        <meta property="og:description" content="Top Target is an emerging leader organization in providing End to End Management consulting services that form the basic building blocks required for implementing the Enterprise Project Management and Human Resources Management. " />
        <meta property="og:type" content="website" />
      </Helmet>
        <Navbar/>
        <Toaster position="top-right"/>
        <UserContext.Provider value={{user}}>
            <div className="bg-slate-50 ">
            <Outlet/>
            </div>
        </UserContext.Provider>
    
        <Footer/>
    </div> );
}
 
export default WebsiteLayout;
