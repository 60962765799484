
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/homePage';
import WebsiteLayout from './layouts/websiteLayout';
import ContactPage from './pages/contactpage';
import WhyUs from './pages/whyUs';
import AboutUs from './pages/aboutus';
import DashboardLayout from './layouts/dashboardLayout';
import DashboardPage from './dashoard/dashboardPage';
import DashboardBlog from './dashoard/dahboardBlog';
import DashboardJobPosts from './dashoard/dashboardJobPosts';
import LoginPage from './auth/loginPage';
import RegisterPage from './auth/registerPage';
import PrivateRoute from './auth/privateRoute';
import DashboardCv from './dashoard/dashboardCv';
import CompanyProfile from './dashoard/dashboardCompanyProfile';
import DashboardUsers from './dashoard/dashboardUsers';
import DashboardCompanies from './dashoard/dashboardCompanies';
import DashboardInquiries from './dashoard/dashboardInquiries';
import JobsPage from './pages/jobsPage';
import JobDetails from './pages/jobDetails';
import BlogPostDetails from './pages/blogPostDetails';
import BlogPage from './pages/blogPage';
import ServicesLayout from './layouts/servicesLayout';
import HrPlanning from './pages/services/hrPlanning';
import JobAnalysis from './pages/services/jobAnalysis';
import HrRecruitment from './pages/services/hrRecruitmentAndSelection';
import PerformanceManagement from './pages/services/perfomanceManagement';
import TrainingAndCareerDevelopment from './pages/services/trainingAndCareerDevelopment';
import CompansationAndBenefits from './pages/services/compansationAndBenefits';
import IndustrialRelation from './pages/services/industrialRelation';
import HRInformationSystem from './pages/services/hrInformationSystem';
import HumanResourceAudits from './pages/services/humanResourceAudits';
import Partners from './pages/partners';
import DashboardPublishedJobs from './dashoard/dashboardPublishedJobs';
import ApplyForAJob from './pages/applyForAJob';
import DashboardApplications from './dashoard/dashboardApplications';
import DashboardUnpublishedJobs from './dashoard/dashboardUnpublishedJobs';
import DashboardSubscribers from './dashoard/dashboardSubscribers';

function App() {

  return (
    <BrowserRouter>
    <Routes>
      <Route path='/login' index element={ <LoginPage/>}/>
      <Route path='/register' index element={<RegisterPage/>}/>
      <Route path='/' element={<WebsiteLayout/>}>
      <Route path='/' index element={<HomePage/>}/>
      <Route path='/contactus'  element={<ContactPage/>}/>
      <Route path='/jobs'  element={<JobsPage/>}/>
      <Route path='/blog'  element={<BlogPage/>}/>
      <Route path='/blogPostDetails/:uuid'  element={<BlogPostDetails/>}/>
      <Route path='/jobDetails/:uuid'  element={<JobDetails/>}/>
      <Route path='/applyForAJob/:uuid'  element={<ApplyForAJob/>}/>

      <Route path='/whyus'  element={<WhyUs/>}/>
      <Route path='/aboutus' element={<AboutUs/>}/>
      <Route path='/partners' element={<Partners/>}/>

      <Route path='/services' element={<ServicesLayout/>}>
        <Route path='/services/hrPlanning' element={<HrPlanning/>}/>
        <Route path='/services/jobAnalysis' element={<JobAnalysis/>}/>
        <Route path='/services/hrRecruitment' element={<HrRecruitment/>}/>
        <Route path='/services/performanceManagement' element={<PerformanceManagement/>}/>
        <Route path='/services/training' element={<TrainingAndCareerDevelopment/>}/>
        <Route path='/services/compensation' element={<CompansationAndBenefits/>}/>
        <Route path='/services/industrialRelation' element={<IndustrialRelation/>}/>
        <Route path='/services/hrInformationSystem' element={<HRInformationSystem/>}/>
        <Route path='/services/humanResourceAudits' element={<HumanResourceAudits/>}/>
      </Route>
      </Route>
      <Route path='/dashboard' element={<PrivateRoute><DashboardLayout/></PrivateRoute> }>
      <Route path='/dashboard' index element={<DashboardPage/>}/>
      <Route path='/dashboard/cv'  element={<DashboardCv/>}/>
      <Route path='/dashboard/users'  element={<DashboardUsers/>}/>
      <Route path='/dashboard/inquiries'  element={<DashboardInquiries/>}/>
      <Route path='/dashboard/subscribers'  element={<DashboardSubscribers/>}/>
      <Route path='/dashboard/companies'  element={<DashboardCompanies/>}/>
      <Route path='/dashboard/applications'  element={<DashboardApplications/>}/>
      <Route path='/dashboard/companyProfile'  element={<CompanyProfile/>}/>
      <Route path='/dashboard/blog'  element={<DashboardBlog/>}/>
      <Route path='/dashboard/jobPosts'  element={<DashboardJobPosts/>}/>
      <Route path='/dashboard/publishedJobs'  element={<DashboardPublishedJobs/>}/>
      <Route path='/dashboard/unpublishedJobs'  element={<DashboardUnpublishedJobs/>}/>


      </Route>
    </Routes>
    </BrowserRouter>
  );
}

export default App;
