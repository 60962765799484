const HRInformationSystem = () => {
    return ( <div>
                  <p className=" font-medium ">Top Target will ensure your organization with reliable and user friendly Human Resource Information Systems to facilitate different Human resources processes like acquiring, storing, analyzing and distributing information to various stakeholders. HRIS will enable improvement in traditional processes and enhance strategic decision making in your organization. Top Target HRIS will give you and your organization a hand in:</p>
       <ul className="list-disc pl-5 mt-5 space-y-2">
        { [
        "Recruitment and selection systems",
        "Payroll management systems",
        "Collective Bargaining",
        "HR planning systems",
        "Learning management systems",
        "Performance Management systems",
        "Time and Attendance management systems",
        "Grievance and disciplinary handling systems"
    ].map((item)=>{
            return <li>{item}</li>
        })
}
       </ul>
    </div> );
}
 
export default HRInformationSystem;