const PerformanceManagement = () => {
    return ( <div>
             <p className=" font-medium ">Top Target will work hand in hand with you and your teams to measure the organizational strategic objectives together with your operational objectives. Top Target will guide your organization in implementation of the Performance Management programs through the following steps:</p>
       <ul className="list-disc pl-5 mt-5 space-y-2">
        { [
"Identifying the purpose of Performance Management Program.",
"Identifying the required Performance Management Method.",
"Establishing the Performance Management rights and responsibilities.",
"Applying the Performance Management program phases.",
"Setting the rating definitions."
].map((item)=>{
            return <li>{item}</li>
        })
}
       </ul> 
    </div> );
}
 
export default PerformanceManagement;