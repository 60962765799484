import { useEffect, useState } from "react";
import InquiryForm from "../components/inquiryForm";
import {useNavigate} from 'react-router-dom'
const HomePage = () => {
    const [currentSlide, setcurrentSlide] = useState(0);
    const slides = [
    {
        image:'/slide2.jpg',
        title:"Human <br/> Resources Planning",
        description:"We offer strategic guidance, helping you to streamline HR processes for future growth. "
    },
    {
        image:'/slide3.jpg',
        title:"Job <br/> Analysis",
        description:"We conduct thorough analysis, helping you: optimize job roles and responsibilities."
    },
    {
        image:'/slide4.jpg',
        title:"Human Resources <br/> Recruitment & Selection",
        description:"We provide recruitment expertise, helping you to find the best talent."

    },
    {
        image:'/slide2.jpg',
        title:"Performance <br/> Management",
        description:"We implement performance systems, helping you enhance employee productivity. "
    },
    {
        image:'/slide3.jpg',
        title:"Training and <br/>  Career Development",
        description:"We design tailored programs, helping you to empower your team's growth."
    },
    {
        image:'/slide4.jpg',
        title:"Compensation & <br/> Benefits",
        description:"We offer comprehensive packages, helping you to attract and retain top talent."

    },
    {
        image:'/slide2.jpg',
        title:"Industrial <br/> Relations",
        description:"We provide conflict resolution, helping you to foster a harmonious workplace. "
    },
    {
        image:'/slide3.jpg',
        title:"Human Resources <br/> Information Systems",
        description:"We integrate digital solutions, helping yo streamline HR operations."
    },
    {
        image:'/slide4.jpg',
        title:"Human <br/> Resources Audits",
        description:"We conduct thorough assessments, helping you to ensure compliance and efficiency."

    },
    {
        image:'/slide2.jpg',
        title:"Labor <br/> Outsourcing Services",
        description:"We offer skilled staffing, helping you to reduce operational costs."
    },
] 

    useEffect(() => {
        let currentSlide = 0;
        setInterval(() => {
            setcurrentSlide(currentSlide)
            currentSlide = currentSlide +1;
            if(currentSlide == slides.length){
                currentSlide = 0
            }
        }, 8000);
    }, []);
    const navigate = useNavigate()
    return ( <div>
        <div className=" h-[40%] md:h-[70%]  bg-white 
         bg-no-repeat
         transition-all duration-700 " 
        style={{ backgroundImage:`url(${slides[currentSlide].image})`,
        backgroundSize:'cover' }}>
            <div className=" w-full  bg-black bg-opacity-30">
                <div className="w-11/12 flex items-center h-[30vh] md:h-[70vh]  mx-auto ">
                    <div className={`${currentSlide==2?"w-6/12":"w-8/12 "} transition-all duration-700 `}>
                    <h1 className="text-4xl font-bold leading-[60px] text-white w-full hidden md:block" dangerouslySetInnerHTML={{ __html:slides[currentSlide].title }}></h1>
                    <p className="mt-3 text-lg py-3 w-full hidden text-white md:block">{slides[currentSlide].description}</p>
                    <div className="flex space-x-4 mt-8 ">
                   <div>
                   <button onClick={()=>{
                        navigate('/contactUs')

                    }} className="px-4 py-4 w-full hidden md:block bg-green-600 text-sm font-bold text-white">CONTACT US</button>
                    
                   </div>
                   <div>
                   <button onClick={()=>{
                        navigate('/services/hrPlanning')

                    }} className="px-4 py-4 w-full hidden md:block bg-slate-900 text-sm font-bold text-white">OUR SERVICES</button>
                   
                   </div>
                   
                   
                    </div>
                    </div>
                  
                </div>
                <div className="  w-full  mb-8  h-full flex items-center">
                    <div className="flex justify-center just space-x-2   w-full">
                        {[...slides.map((item,index)=>index)].map((item)=>{
                            return  <div onClick={()=>{
                                setcurrentSlide(item)
                            }} className={`h-4 w-4 cursor-pointer rounded-full ${currentSlide==item?`bg-green-600`:'bg-white'}`}></div>
                        })}
                         
                    
                    </div>
                  </div>
                <div className="bg-slate-900   py-4 ">
                   <div className="w-11/12 mx-auto flex items-center justify-between">
                   <h1 className="text-xl text-white">Discover daily job vacancies posted on our Job Portal.</h1>
                    <button onClick={()=>{
                        navigate('/jobs')
                    }} className="px-4 py-2 bg-green-600 font-bold text-white">search</button>
               
                   </div>
                </div>
            </div>
        </div>
         
            <div className="grid grid-cols-1 gap-8 md:gap-2 md:grid-cols-3 w-11/12 mx-auto py-12 md:py-24">
            {[
                {title:"Why Choose Us?",description:"Our goal is to optimise your business relationships by tailoring our services to your individual requirement",icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" 
                strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
              </svg>
              },
                {title:"Pay for Success",description:"We at Top target always focus to multiply your investments by providing top quality services.",
                icon:<svg xmlns="http://www.w3.org/2000/svg" fill="none" 
                viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" 
                className="w-12 h-12">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
              </svg>
              
              },
                {title:"Tailor-Made Solutions",description:"We thorowly understand your problems first and then provide a custome solution.",
                icon:<svg xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                  className="w-12 h-12">
                <path strokeLinecap="round" strokeLinejoin="round" d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z" />
              </svg>
              
              },

            ].map((item)=>{
                return <div className="flex flex-col items-center text-center">
                    {item.icon}
                    <h1 className="text-2xl font-bold">{item.title}</h1>
                    <h1 className=" mt-2">{item.description}</h1>
                </div>
             })}
            </div>
            <div className="w-11/12 mx-auto ">
                <div className="grid grid-cols-1 gap-y-8 md:grid-cols-2 gap-x-12 items-center">
                    <div>
                    <h1 className="text-3xl font-bold">About TopTarget HR Consultancy</h1>
                    <p className="mt-3 ">Top Target is uniquely giving your business or organization a right hand to success through people. Unlike other consulting firms in Human resources and Training, Top Target is focusing more on giving you Results than Reports only. For every engagement, we assemble a team with the most appropriate experience and expertise. Our goal is to optimise your business relationships by tailoring our services to your individual requirements</p>
                    <p className="mt-3 ">We strive to build a relationship of trust with every client, for the long-term.70% of our work is for clients that we have served for over 10 years.</p>

                    </div>
                    <div>
                        <img className="" src="/home.jpg"/>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-y-8 md:grid-cols-2 gap-x-12 items-center mt-12">
                <div>
                        <img className="" src="/girl.webp"/>
                    </div>
                    <div>
                    <h1 className="text-3xl font-bold">CSR TopTarget HR Consultancy</h1>
                    <p className="mt-3 ">Our corporate social responsibility policy is built on three pillars: employee volunteerism, corporate giving and responsible business practices. By getting actively involved in their communities, Top Target employees can effect real change on a relational level.By offering a matching gift program, Top Target employees are able to multiply their financial generosity to a variety of worthwhile aid organizations.</p>
                    <p className="mt-3 ">We strive to build a relationship of trust with every client, for the long-term.70% of our work is for clients that we have served for over 10 years.</p>

                    </div>
                  
                </div>
            </div>
            <div className="py-12 md:py-24">
                <div className="w-11/12 md:w-6/12 mx-auto text-center mb-8">
                <h1 className="text-center font-bold text-4xl">Our Services</h1>
                <p className="mt-3 ">Top Target provides different Services in the Domain of Human Resources Management. Below our basic services in this domain</p>
             
                </div>
               
              <div className="grid grid-cols-1 md:grid-cols-3 w-11/12 mx-auto gap-8">
                {[
                    {title:"HR Planning",
                    description:"Complete HR Planning consultation.",
                    icon:<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" 
                    strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 mt-2 text-green-600 p-2 bg-slate-900">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                  </svg>
                  
                    },
                    {title:"Job Analysis",
                    description:"Proffessionally evaluated Job Analysis for any organization",
                    icon:<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" 
                    strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 mt-2 p-2 text-green-600  bg-slate-900">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                  </svg>
                  
                    },
                    {title:"Recruitment & Selection",
                    description:"We do recruitment and selection process based on your guidelines on your behalf.",
                    icon:<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                     className="w-12 h-12 mt-2 text-green-600  bg-slate-900 p-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                  </svg>
                    },
                    {title:"Peformance Management",
                    description:"Management both manualy and automation through systems.",
                    icon:<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" 
                    strokeWidth={1.5} stroke="currentColor" 
                    className="w-12 h-12 mt-2 text-green-600  bg-slate-900 p-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                  </svg>
                  
                    },
                    {title:"Training & Career Development",
                    description:"Career counsling and training services.",
                    icon:<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     strokeWidth={1.5} stroke="currentColor" 
                     className="w-12 h-12 mt-2 text-green-600  bg-slate-900 p-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672Zm-7.518-.267A8.25 8.25 0 1 1 20.25 10.5M8.288 14.212A5.25 5.25 0 1 1 17.25 10.5" />
                  </svg>
                  
                  
                    },
                    {title:"Human resources Information Systems",
                    description:"A completely customizable HR systems that works for you.",
                    icon:<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" 
                    strokeWidth={1.5} stroke="currentColor"
                     className="w-12 h-12 mt-2 text-green-600  bg-slate-900 p-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z" />
                  </svg>
                    },
                    ,
                    {title:"Compensation, Benefits",
                    description:"Analysis as per market norms,Correct benefites to employeees.",
                    icon:<svg xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" 
                     className="w-12 h-12 mt-2 text-green-600  bg-slate-900 p-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
                  </svg>
                    },
                    {title:"Industrial Relations",
                    description:"High Quality outcomes through our IR services.",
                    icon:<svg xmlns="http://www.w3.org/2000/svg" fill="none" 
                    viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" 
                    className="w-12 h-12 mt-2 text-green-600  bg-slate-900 p-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V13.5Zm0 2.25h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V18Zm2.498-6.75h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V13.5Zm0 2.25h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V18Zm2.504-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5Zm0 2.25h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V18Zm2.498-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5ZM8.25 6h7.5v2.25h-7.5V6ZM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0 0 12 2.25Z" />
                  </svg>
                  
                    },
                    {title:"Human Resources Audits",
                    description:"Best and unbaised quality Audits.",
                    icon:<svg xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" 
                     className="w-12 h-12 mt-2 text-green-600  bg-slate-900 p-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75" />
                  </svg>
                  
                    },
                   ].map((item)=>{
                    return <div  className="flex space-x-4">
                        <div className="">{item.icon}</div>
                        <div >
                            <h1 className="text-2xl leading-6 mb-2 font-bold">{item.title}</h1>
                            <p className="">{item.description}</p>
                        </div>
                    </div>
                })}
               </div>


            </div>
            <div style={{ backgroundImage:"url(/9.jpg)" }} className=" bg-slate-900  text-white text-center mb-24">
               <div className="bg-slate-900 bg-opacity-90 py-12 md:py-24">
               <div className=" w-11/12 md:w-9/12 mx-auto space-y-5 ">
                <h1 className="font-bold text-4xl">Looking for Awards Winning Business Consultant?</h1>
                <p className="mt-3">The Consumers' Choice Award was established to identify and promote business excellence. The award utilizes a unique survey that asks consumers to choose the best in their city. Leading independent research organizations</p>
                <p className="text-xl font-bold mt-3">Lets Start Your Business Today - <span className="text-green-600">Contact Us</span> </p>
                </div>
               </div>
                
            </div>
            <div className="w-11/12 mx-auto mb-24">
                <div className="grid grid-cols-1 gap-y-8 md:grid-cols-2 gap-x-6 items-center">
                    <div>
                    <h1 className="font-bold text-4xl">Request a Call Back</h1>
                <p className="mt-5 text-xl font-bold ">If you would like to contact someone by phone, fax or post and you don't know the address, please consult our world wide branch directory.</p>
                <p className="mt-5 ">For Business: For Business inquiry fill our short feedback form or you can also send us an email and we’ll get in touch shortly, or give us a call on -<span className="text-slate-900 font-bold">(+255) 677274141.</span> </p>
                <p className="mt-5 "><span className="font-bold text-slate-900">For Customer Support :</span> If you have any doubt about ConsultPress WordPress or how we can support your business, Send us an <span className="text-green-600 font-bold">email</span> and we’ll get in touch shortly, or Contact via <span className="text-green-600 font-bold">Support Forum</span> </p>
                <p className="mt-5 "><span className="font-bold text-slate-900">Office Hours :</span>  07:30 and 19:00 Monday to Saturday,Sunday - Holiday</p>
                    </div>
                    <div className=" bg-gray-100 px-8 py-8 ">
                        <InquiryForm/>
                      
                    </div>
                </div>
            </div>

    </div> );
}
 
export default HomePage;