const HumanResourceAudits = () => {
    return ( <div>
          <p className=" font-medium ">Top Target will provide your organization with an HR Audit that will include a comprehensive discussion and review of current practices, policies, and procedures, and will include benchmarking against organizations of similar size and/or industry. Specific areas to be examined in the audit will include:</p>
       <ul className="list-disc pl-5 mt-5 space-y-2">
        { [
     "Compliance with applicable employment and labor relations Act",
     "Record-keeping (personnel files, applications, etc.)",
     "Compensation/pay equity",
     "Employee relations",
     "Performance appraisal systems",
     "Policies and procedures/employee handbook",
     "Terminations",
     "Health, safety and security (OSHA compliance, Drug-Free Workplace, Workplace Violence)"
    ].map((item)=>{
            return <li>{item}</li>
        })
}
       </ul>
    </div> );
}
 
export default HumanResourceAudits;