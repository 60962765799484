const IndustrialRelation = () => {
    return ( <div>
             <p className=" font-medium ">Top Target will closely work with you on Industrial relation issues including but not limited to:</p>
       <ul className="list-disc pl-5 mt-5 space-y-2">
        { [
       "Industrial relations Systems",
       "Trade Unions",
       "Collective Bargaining",
       "Strikes",
       "Employees Grievance",
       "Employees discipline",
       "Labor cases representations and advise"
    ].map((item)=>{
            return <li>{item}</li>
        })
}
       </ul>
    </div> );
}
 
export default IndustrialRelation;