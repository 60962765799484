const CompansationAndBenefits = () => {
    return ( <div>
             <h1 className="text-2xl font-bold mb-2">Compensation</h1>
                 <p className=" font-medium ">Top Target understands how crucial this service is for your organization growth. We will work closely with your organization to reach to the most suitable wage structure that is compliant with the wage curve, pay grades, and rate ranges. This service will enable your organization to achieve the following:</p>
       <ul className="list-disc pl-5 mt-5 space-y-2">
        { [
    "To reward employee’s past performance",
    "To remain competitive in the labor market",
    "To maintain salary equity among employees",
    "To mesh employees future performance with organization goals",
    "To control the compensation budget",
    "To attract new talents",
    "To reduce unnecessary turnover"

].map((item)=>{
            return <li>{item}</li>
        })
}
       </ul> 
         <h1 className="text-2xl font-bold mb-2 mt-5">Incentive Rewards</h1>
                 <p className=" font-medium ">Incentive plans emphasize a shared focus on organizational objectives by broadening the opportunities for incentives to employees throughout the organization. Below are the major outputs of this service:</p>
       <ul className="list-disc pl-5 mt-5 space-y-2">
        { [
"Characteristics of a successful incentive plan",
"Financial incentives that are linked to valued behavior",
"Fair incentive program",
"Challenging, yet achievable productivity and quality standards",
"Simple and understandable payout formula"
].map((item)=>{
            return <li>{item}</li>
        })
}
       </ul> 
       <h1 className="text-2xl font-bold mt-3 mb-2">Employee Benefits</h1>
       <p className=" font-medium ">Top Target will work with you to develop the most appropriate benefit plans. These plans will include, but not limited to the following:</p>
       <ul className="list-disc pl-5 mt-5 space-y-2">
        { [
  "Pension",
  "Variable pay",
  "Worker’s compensation",
  "Health benefits",
  "Time-off programs"
].map((item)=>{
            return <li>{item}</li>
        })
}
       </ul> 
    </div> );
}
 
export default CompansationAndBenefits;