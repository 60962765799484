const HrPlanning = () => {
    return ( <div className="">
        <p className=" font-medium ">Top Target will work closely with you and your teams to have the best plans for your Human Resources which will maximize your business and productivity, through:</p>
       <ul className="list-disc pl-5 mt-5 space-y-2">
        { [
        "Identifying and documenting the different HR processes that will govern all the HR activities within your organization.",
        "Establishing a Human Resource Management System (HRMS) that will help your organization to streamline the HR activities.",
        "Working closely with you and your teams to implement the HRMS and follow up on the implementation to ensure a successful roll out.",
        "Identifying the right number and type of employees that are necessary to implement a chosen business plan or your organization’s strategic objectives.",
        "Identifying and initiating programs needed to develop and strengthen your organizational capabilities upon which future strategies can be built.",
        "Forecasting demand for labor, performing supply analysis, and balancing supply and demand consideration."
        ].map((item)=>{
            return <li>{item}</li>
        })
}
       </ul>
    </div> );
}
 
export default HrPlanning;