const TrainingAndCareerDevelopment = () => {
    return ( <div>
       <h1 className="text-2xl font-bold mb-2">Training Development</h1>
                 <p className=" font-medium ">Top Target will work with you and your esteemed organization to implement the four phases of this service as follows:</p>
       <ul className="list-disc pl-5 mt-5 space-y-2">
        { [
 "Training Needs Assessments",
 "Designing Training Programme",
 "Implementation of the Training Programme",
 "Evaluation"
].map((item)=>{
            return <li>{item}</li>
        })
}
       </ul> 
       <h1 className="text-2xl font-bold mt-3 mb-2">Career Development</h1>
       <p className=" font-medium ">Top Target will set the guidelines for your organization to develop the employee’s career and help to achieve a high retention rate within your organization. The guidelines will help you and your organization to do the following tasks:</p>
       <ul className="list-disc pl-5 mt-5 space-y-2">
        { [
  "Match individual and organization needs",
  "Identify career opportunities & requirements",
  "Gauge Employee Potential",
  "Introduce Career Development initiatives"
].map((item)=>{
            return <li>{item}</li>
        })
}
       </ul> 
    </div> );
}
 
export default TrainingAndCareerDevelopment;