import { useState } from "react";
import { addSubscriber } from "../controllers/subscriberController";
import {toast} from "react-hot-toast";
import Spinner from "./spinner";
import emailjs from '@emailjs/browser';

const Footer = () => {
  
    const [loading, setLoading] = useState(false);
    return ( <div style={{ backgroundImage:"url(/footer.jpg)" }} className="bg-slate-900 bg-no-repeat text-white ">
        <div className="py-12 md:py-24 bg-opacity-95 bg-slate-900 ">
            <div className="w-11/12 mb-12 mx-auto items-center grid grid-cols-12">
                <div className="col-span-6">
                    <h1 className="font-bold text-3xl mb-2">Subscribe to our newsletter</h1>
                    <p>Your will be receiving our daily updates</p>
                </div>
                <form    onSubmit={(e)=>{
                    e.preventDefault()
                    setLoading(true)
                    const data = {
                        phone:e.target.phone.value,
                        email:e.target.email.value,
                    }

                   
                    addSubscriber(data).then(()=>{
                    toast.success("Subscribed successfully")
                    const emailData = {
                        from_name:'John Chuma',
                        to_email:'johnvchuma@gmail.com',
                        from_email:data.email,
                        message:'You have a new subscribers'
                    }
                    emailjs.send('service_7bswvb7', 'template_6dwgdmj',emailData, 'MeMcQVi2ahZZslaG_')
                      .then((result) => {
                          console.log(result.text);
                          setLoading(false)
                          e.target.email.value = "";
                            e.target.phone.value = "";

                      }, (error) => {
                          setLoading(false)
                          console.log(error);
                          throw error
                      });


                    })
                    
                }} className="flex justify-end space-x-3 col-span-6">
                    <div className="w-full">
                    <input type="tel" required name="phone" placeholder="Enter your phone number"
                     className="w-full  text-black py-3  focus:border-0"/>
                        </div>  
                    <div className="w-full">
                    <input type="email" required name="email" placeholder="Enter your email address"
                     className="w-full  text-black py-3  focus:border-0"/>
                        </div>                    
                    <div>
                    <button type="submit" className="bg-green-600 font-bold w-36 flex justify-center items-center  py-3 px-4">
                        {loading?<Spinner/>:"Subscribe"}
                        </button>
                    </div>
                </form>
            </div>
        <div className="w-11/12 mx-auto grid grid-cols-1 gap-y-8 md:grid-cols-4 gap-x-12">
            <div className="   ">
                <img className="h-8 mb-5 mt-2" src="/logo.png"/>
                <p>
                Top Target is an emerging leader organization in providing End to End Management consulting services that form the basic building blocks required for implementing the Enterprise Project Management and Human Resources Management.
                </p>
            </div>
            <div>
                <h1 className="text-2xl font-bold">Quick Links</h1>
                <div className="w-24 h-1 bg-green-600 mt-2 mb-4"></div>
                <div className="space-y-3">
                {[
                    {title:"Home",path:""},
                    {title:"About us",path:""},
                    {title:"Services",path:""},
                    {title:"Contact us",path:""},

            ].map((item)=>{
                return <p key={item.title}>{item.title}</p>
            })}
                </div>
                
            </div>
            <div>
                <h1 className="text-2xl font-bold">Services</h1>
                <div className="w-20 h-1 bg-green-600 mt-2 mb-4"></div>
                
<div className="space-y-3">
    
{[
                    {title:"HR Planning",path:""},
                    {title:"Job Analysis",path:""},
                    {title:"HR Recruitment & Selection",path:""},
                    {title:"Performance Management",path:""},
                    {title:"Training and Career Development",path:""},
                    {title:"Compensation & Benefits",path:""},
                    {title:"Industrial Relations",path:""},
                    {title:"HR Information Systems",path:""},
                    {title:"Human Resources Audits",path:""},


            ].map((item)=>{
                return <p key={item.title}>{item.title}</p>
            })}
</div>
            </div>
            <div>
                <h1 className="text-2xl font-bold">Contact</h1>
                <div className=" w-20 h-1 bg-green-600 mt-2 mb-4"></div>
               <div className=" space-y-5">
               <div className="flex  items-start space-x-3">
               <div>
               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} 
               stroke="currentColor" className="w-8 h-8 text-green-600">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                </svg>
               </div>
                <div>
                <h1>Elite Tower, Place Building, 9th Floor, Azikiwe Street, Office Hub, Ilala Municipality-DSM</h1>
                </div>           
               </div>
               <div className="flex items-start space-x-3">
               <div>
               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} 
               stroke="currentColor" className="w-8 h-8 text-green-600">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
</svg>

               </div>
                <div>
                <h1>Mobile: +255677274141</h1>
                </div>           
               </div>
               <div className="flex  items-start space-x-3 ">
               <div>
               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} 
               stroke="currentColor" className="w-8 h-8 text-green-600">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 9v.906a2.25 2.25 0 0 1-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 0 0 1.183 1.981l6.478 3.488m8.839 2.51-4.66-2.51m0 0-1.023-.55a2.25 2.25 0 0 0-2.134 0l-1.022.55m0 0-4.661 2.51m16.5 1.615a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V8.844a2.25 2.25 0 0 1 1.183-1.981l7.5-4.039a2.25 2.25 0 0 1 2.134 0l7.5 4.039a2.25 2.25 0 0 1 1.183 1.98V19.5Z" />
</svg>

               </div>
                <div>
                <h1>info@toptarget.co.tz</h1>
                <h1>victoria@toptarget.co.tz</h1>

                </div>           
               </div>
               </div>
            </div>
            

        </div>
        <div className="w-11/12 mx-auto mt-5 font-bold">
            <h1>© 2024 Top Target Limited.</h1>
        </div>
        </div>
       
    </div> );
}
 
export default Footer;