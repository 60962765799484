import { useState } from "react";
import {useNavigate,useLocation} from "react-router-dom"
import MiniSidebar from "../components/minSidebar";
const WhyUs = () => {
    const navigate =  useNavigate()
    const {pathname}= useLocation()
    const [currentSlide, setcurrentSlide] = useState(0);
    return ( <div>
  <div className="bg-slate-900 text-white flex py-12 md:py-24 flex-col justify-center items-center">
    <h1 className="text-4xl font-bold">Why Top Target ?</h1>
    <div className="w-12 h-2 bg-green-600 mt-3"></div>
  </div>
  <div className="bg-slate-800 text-white flex py-8  justify-center items-center">
    <h1 className="text-sm font-bold">Home</h1>
    <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" 
    className="w-4 h-4 text-green-600">
    <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd" />
    </svg>
    </div>
    <h1 className="text-sm font-bold">Why Top Target</h1>
  </div>
<div className="w-11/12 mx-auto my-12 md:my-24">
                <div className="grid grid-cols-1 md:grid-cols-12 gap-x-6 items-start">
                    <div className="col-span-4">
                      <MiniSidebar/>                         
                <div>

                </div>
                    </div>
                   <div className=" col-span-8 space-y-4">
                    <h1 className="text-3xl font-bold">Why Choose us ?</h1>
                    <p className="">Top Target is uniquely giving your business or organization a right hand to success through people. Unlike other consulting firms in Human resources and Training, Top Target is focusing more on giving you Results than Reports only.</p>
                    <p className="">Our team is uniquely made of talented and well experienced professionals in Human Resources Management, Legal and Training gained from government, Private and international organizations.</p>
                   </div>
                </div>
            </div>
    </div> );
}
 
export default WhyUs;