const HrRecruitment = () => {
    return ( <div>
          <p className=" font-medium ">Understanding how important and yet time consuming this exercise is to your organization, Top Target will do the recruitment and selection process for your organization. This can be as an integrated joint service or a completely outsourced one. This service will involve the following tasks:</p>
       <ul className="list-disc pl-5 mt-5 space-y-2">
        { [
      "Identifying the staffing management system for the organization. The staffing management system will perform the following functionality: \n\n a. Tracking application information \n\n b. Scanning resumes \n\n c. Making the information immediately accessible to line managers to search online for internal and external talents.",
      "Providing as much reliable and valid information as possible about applicants so that their qualifications can be carefully matched with job specifications.",
      "Performing the screening process to short list candidates.",
      "Conducting interview and providing you with the recommended candidate or set of candidates for final selection.",
      "Conduct background checks to the selected candidates for your organization.",
      "Prepare and issue offer and contract of employment to the successful talent for your organization."
].map((item)=>{
            return <li>{item}</li>
        })
}
       </ul> 
    </div> );
}
 
export default HrRecruitment;