import { services } from "../utils/arrays";
import {useLocation,Outlet,useNavigate} from "react-router-dom"

const ServicesLayout = () => {
  const {pathname} =  useLocation()
  const navigate = useNavigate()
    return ( 
    <div>
          <div className="bg-slate-900 text-white flex py-12 md:py-24 flex-col justify-center items-center">
    <h1 className="text-4xl text-center md:text-start font-bold">{services.filter((item)=>item.path == pathname)[0].title}</h1>
    <div className="w-12 h-2 bg-green-600 mt-3"></div>
  </div>
  <div className="bg-slate-800 text-white flex py-8  justify-center items-center">
    <h1 className="text-sm font-bold">Home</h1>
    <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" 
    className="w-4 h-4 text-green-600">
    <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd" />
    </svg>
    </div>
    <h1 className="text-sm font-bold">Services</h1>
    <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" 
    className="w-4 h-4 text-green-600">
    <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd" />
    </svg>
    </div>
    <h1 className="text-sm font-bold">{services.filter((item)=>item.path == pathname)[0].title}</h1>
  </div>
  <div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 w-11/12 mx-auto py-12">
        <div className="col-span-3">
        <div className="bg-gray-100 border-l-4 mb-4  border-orange-400">
                        <div className="space-y-4 py-8">
                        {services.map((item)=>{
                            return <div  className={` px-4 text-lg cursor-pointer -translate-x-1 ${item.path==pathname?"border-l-4 border-slate-900":"border-l-4 border-orange-400"} font-bold`}
                             onClick={()=>{
                                navigate(item.path)
                            }
                            }>{item.title}
                            </div>
                        })}
                        </div>
                       </div>
        </div>
        <div className="col-span-9 px-0 md:px-24">
            <Outlet/>
        </div>
    </div> 
    </div>
   );
}
 
export default ServicesLayout;