import { useNavigate } from "react-router-dom";
import { auth } from "../utils/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { services } from "../utils/arrays";
const Navbar = () => {
    const navigate = useNavigate();
    const [showMenu, setshowMenu] = useState(false);
    const [user, setUser] = useState(null);
    const [afterScroll, setAfterScroll] = useState(false);
    useEffect(() => {
      onAuthStateChanged(auth,(user)=>{
        if(user){
           setUser(user)
        }else{
           setUser(null)
        }
   })
   
    }, []);

    useEffect(() => {
       window.addEventListener("scroll",()=>{
        if(window.scrollY >250){
            setAfterScroll(true)
        }else{
            setAfterScroll(false)
        }
       })
    }, []);
    return ( <div className=" w-full">
        <div className="bg-slate-900 text-white text-sm flex justify-between py-2">
            <div className="flex flex-col md:flex-row  md:items-start space-y-2 md:space-y-0 md:space-x-4 w-11/12 mx-auto">
            <div className="flex items-center space-x-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-green-600">
            <path fillRule="evenodd" d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clipRule="evenodd" />
            </svg>
               <h1>Ilala Municipality, Dar Es Salaam</h1>
             </div>
             <div className="flex items-center space-x-1">
             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-green-600">
            <path fillRule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clipRule="evenodd" />
            </svg>
             <h1>Contact Us! +255 677 274 141</h1>
             
             </div>
             <div className="flex items-center space-x-1">
             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-green-600">
            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 0 0 0-1.5h-3.75V6Z" clipRule="evenodd" />
            </svg>
             <h1>Mon to Sat : 8.00 - 18.00</h1>
             
             </div>
            </div>
          <div className="flex">
                
        </div>
        </div>
        <div className="bg-white ">
        <div className="block md:hidden">
            <div className="w-11/12 mx-auto py-5">
             
              <div onClick={()=>{
                    setshowMenu(!showMenu)
                }} className="h-8 w-10 text-white bg-green-600 flex justify-center items-center">
               
               {showMenu?<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
                :<svg xmlns="http://www.w3.org/2000/svg" fill="none" 
                viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" 
                className="w-6 h-6 text-white">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>}
                

                </div>
                <div className=" ">
                {showMenu&&[
                        {title:"Home",path:"/"},
                        {title:"About us",path:"/aboutus"},
                        {title:"Services",path:"/services"},
                        {title:"Why top target",path:"/whyus"},
                        {title:"Clients & partners",path:"/partners"},
                        {title:"Jobs",path:"https://toptarget.talentrecruit.com/career-page"},
                        {title:"Blog",path:"/blog"},
                        {title:"Contact us",path:"/contactus"},
                    ].map((item,index)=>{
                        return <div className="relative group ">
                            <h1 onClick={()=>{
                            if(index !=2){
                                if(item.title == "Jobs"){
                                    window.location.href = "https://toptarget.talentrecruit.com/career-page"
                                }else{
                                    navigate(item.path)
                                }
                                setshowMenu(false)

                            }
                        }} className=" font-bold  text-black z-50 border-t-4 transition-all border-white py-3 hover:border-green-600 uppercase cursor-pointer" key={item.title}>
                            {item.title}</h1>
                            {index==2 &&<div className="absolute z-50 text-white w-60  opacity-0 group-hover:opacity-100 translate-y-6 group-hover:translate-y-0 transition-all duration-700 font-bold left-0 text-start bg-black">
                                 {services.map((item)=>{
                                        return <button onClick={()=>{
                                           navigate(item.path)
                                        }} className=" hidden group-hover:block px-4 hover:bg-green-600 py-2 w-full text-start">{item.title}</button>
                                    })}
                                </div>}
                        </div>
                         
                  })}
                </div>
              </div>
               
            </div>
            <div className="w-11/12 hidden md:block mx-auto py-5">
                <div className="flex justify-between">
                <img className=" h-8 cursor-pointer" onClick={()=>{
                    navigate('/')
                }} src="/logo.png"/>
                <div className="flex  items-center space-x-3">
                {user == null?<button onClick={()=>{
                        navigate('/register')
                    }} className="font-bold">Register</button>:<button onClick={()=>{
                        navigate('/register')
                    }} className="font-bold"></button>
                }
                   
                    <div>
                    {user == null? <button onClick={()=>{
                        navigate('/login')
                      }} className="bg-green-600 font-bold py-2 px-4 text-white">
                        Login
                    </button>:<button onClick={()=>{
                        navigate('/dashboard')
                      }} className="bg-green-600 font-bold py-2 px-4 text-white">
                        Dashboard
                    </button>}
                    </div>

                </div>
                </div>
                <div className="hidden md:block">
                <div className="flex space-x-8 py-0 ">
                    {[
                        {title:"Home",path:"/"},
                        {title:"About us",path:"/aboutus"},
                        {title:"Services",path:"/services"},
                        {title:"Why top target",path:"/whyus"},
                        {title:"Clients & partners",path:"/partners"},
                        {title:"Jobs",path:"https://toptarget.talentrecruit.com/career-page"},
                        {title:"Blog",path:"/blog"},
                        {title:"Contact us",path:"/contactus"},
                    ].map((item,index)=>{
                        return <div className="relative group">
                            <h1 onClick={()=>{
                            if(index !=2){
                                if(item.title == "Jobs"){
                                    window.location.href = "https://toptarget.talentrecruit.com/career-page"
                                }else{
                                    navigate(item.path)
                                }
                            }
                        }} className=" font-bold  text-black border-t-4 transition-all border-white py-3 hover:border-green-600 uppercase cursor-pointer" key={item.title}>
                            {item.title}</h1>
                            {index==2 &&<div className="absolute text-white w-60  opacity-0 group-hover:opacity-100 translate-y-6 group-hover:translate-y-0 transition-all duration-700 font-bold left-0 text-start bg-black">
                                 {services.map((item)=>{
                                        return <button onClick={()=>{
                                           navigate(item.path)
                                        }} className=" hidden group-hover:block px-4 hover:bg-green-600 py-2 w-full text-start">{item.title}</button>
                                    })}
                                </div>}
                        </div>
                         
                  })}
                </div>
                {afterScroll&&    <div className=" shadow-lg fixed top-0 right-0 left-0  w-screen bg-white  py-4 ">
                <div className="flex justify-between w-11/12 mx-auto items-center">
                <img className=" h-8 cursor-pointer" onClick={()=>{
                    navigate('/')
                }} src="/logo.png"/>
                  <div className="flex space-x-4">
                  {[
                        {title:"Home",path:"/"},
                        {title:"About us",path:"/aboutus"},
                        {title:"Services",path:"/services"},
                        {title:"Why top target",path:"/whyus"},
                        {title:"Clients & partners",path:"/partners"},
                        {title:"Jobs",path:"/jobs"},
                        {title:"Blog",path:"/blog"},
                        {title:"Contact us",path:"/contactus"},
                    ].map((item,index)=>{
                        return <div className="relative group">
                            <h1 onClick={()=>{
                            if(index !=2){
                                navigate(item.path)
                            }
                        }} className=" font-bold  text-black border-t-4 transition-all border-white py-3 hover:border-green-600 uppercase cursor-pointer" key={item.title}>
                            {item.title}</h1>
                            {index==2 &&<div className="absolute text-white w-60  opacity-0 group-hover:opacity-100 translate-y-6 group-hover:translate-y-0 transition-all duration-700 font-bold left-0 text-start bg-black">
                                 {services.map((item)=>{
                                        return <button onClick={()=>{
                                           navigate(item.path)
                                        }} className=" hidden group-hover:block px-4 hover:bg-green-600 py-2 w-full text-start">{item.title}</button>
                                    })}
                                </div>}
                        </div>
                         
                  })}
                  </div>
                </div>
                   
                </div>}
                </div>
            </div>
            
            
        </div>
    </div> );
}
 
export default Navbar;