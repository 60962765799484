import { useState } from "react";
import {useNavigate,useLocation} from "react-router-dom"
import MiniSidebar from "../components/minSidebar";
const AboutUs = () => {
    const navigate =  useNavigate()
    const {pathname}= useLocation()
    const [currentSlide, setcurrentSlide] = useState(0);
    return ( <div>
  <div className="bg-slate-900 text-white  py-12 md:py-24 ">

 <div  className="flex flex-col text-center mx-auto justify-center items-center w-11/12 ">
  <h1 className="text-4xl font-bold">Top Target in a Glance</h1>
    <div className="w-12 h-2 bg-green-600 mt-3"></div>
  </div>
 
  </div>
  <div className="bg-slate-800 text-white flex py-8  justify-center items-center">
    <h1 className="text-sm font-bold">Home</h1>
    <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" 
    className="w-4 h-4 text-green-600">
    <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd" />
    </svg>
    </div>
    <h1 className="text-sm font-bold">About Us</h1>
  </div>
<div className="w-11/12 mx-auto my-12 md:my-24">
                <div className="grid grid-cols-1 md:grid-cols-12 gap-x-6 items-start">
                    <div className="col-span-4  ">
                      <MiniSidebar/>                             
                <div>
                </div>
                    </div>
                   <div className=" col-span-8 space-y-8">
                    <div className="space-y-4">
                    <h1 className="text-3xl font-bold">Why Choose us ?</h1>
                    <p className="">Top Target is an emerging leader organization in providing End to End Management consulting services that form the basic building blocks required for implementing the Enterprise Project Management and Human Resources Management. At Top Target, we work closely with our valued customers to increase their readiness level to implement Enterprise Project Management and Human Resources Management systems. This includes Recruitment, training and development, compensation and benefit management and retention programs together with other services. Top Target differentiation is in its focused business model and its specialized Management Consultancy service portfolio that makes Top Target a unique, credible, unbiased service provider. Our achievement is a result of our continuous endeavors to combine talent, quality, and values together.</p>
                    <p className="">Our team is uniquely made of talented and well experienced professionals in Human Resources Management, Legal and Training gained from government, Private and international organizations.</p>
                    </div>
                    <div className="space-y-4">
                    <h1 className="text-3xl font-bold">CEO & FOUNDER – Short Bio</h1>
                    <p className="">Victoria Wilson Mabuba is the CEO and Founder of Top Target Limited. She is the motivation behind the vibrancy of the whole Top Target team to leading a successful business and organization partnership with clients.</p>
                    <p className="">A human Resources and Training Professional, with a Masters Degree in Human Resources Management and a profound experience and knowledge in Adult Education and management ( B.Ed.)She brings into your business and organization an insightful experience in Human Resources Management and Training of more than six year with international and multinational companies and projects.Victoria has a wide network of local and international professionals in Human resources and other fields thus ensuring the clients the best fits wherever it comes to people to accomplish any given Project.</p>
                    </div>
                    <div className="space-y-4">
                    <h1 className="text-3xl font-bold">Our Mission</h1>
                    <p className="">To be a strategic partner to businesses and employers in building their businesses and organizations by maximizing the value of human capital and aligning it with company goals and Values.</p>
                    </div>
                   </div>
                </div>
            </div>
    </div> );
}
 
export default AboutUs;